// eslint-disable-next-line no-restricted-imports -- this is fine getting the TOptions
import { TOptions } from 'i18next';
import MessageFormat from 'intl-messageformat';

import { errorReporter } from '../../../utils';
import { getCurrentLocaleInfo } from '../helpers';

export const messageFormatProcessor = {
  type: 'postProcessor',
  name: 'messageFormat',
  process: function (str: string, keys: string[], options: TOptions) {
    return format(str, keys, options);
  },
} as const;

function format(str: string, keys: string[], options: TOptions) {
  const locale = getCurrentLocaleInfo();

  try {
    return new MessageFormat(str, locale.messageFormat).format(options.replace);
  } catch (e: unknown) {
    if (e instanceof Error) {
      errorReporter.error(e, { keys, options });
    }

    return str;
  }
}
