import React, { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { InView } from 'react-intersection-observer';

import { Button } from '@peakon/bedrock/react/button';
import { Spinner } from '@peakon/bedrock/react/spinner';
import { Typography } from '@peakon/components';
import { Segment } from '@peakon/records';
import { t } from '@peakon/shared/features/i18next/t';

import { queryKeys } from './queries/queryKeys';
import { useGetSegmentsInfiniteQuery } from './queries/useGetSegmentsInfiniteQuery';
import { useUpdateSegmentMutation } from './queries/useUpdateSegmentMutation';
import { SegmentBenchmarkForm } from './SegmentBenchmarkForm/SegmentBenchmarkForm';
import { catchHandler } from '../../../../actions/NotificationActions';
import { useAppDispatch } from '../../../../utils/reduxHooks';
import ContextLabel from '../../../ContextLabel';
import { BenchmarkLabel } from '../BenchmarkLabel/BenchmarkLabel';

import styles from './styles.css';

export const SegmentsBenchmark = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { mutate: removeSegment, isLoading: isLoadingRemoval } =
    useUpdateSegmentMutation();
  const { segments, fetchNextPage, hasNextPage, isLoading } =
    useGetSegmentsInfiniteQuery();
  const [removedId, setRemovedId] = useState<string | null>(null);

  const handleSegmentAdded = () =>
    queryClient.invalidateQueries({ queryKey: queryKeys.segments() });

  const handleRemove = (segmentId: string) => {
    setRemovedId(segmentId);
    removeSegment(segmentId, {
      onError: (error) => dispatch(catchHandler(error)),
      onSettled: () => setRemovedId(null),
    });
  };

  return (
    <div className={styles.root}>
      <Typography.H4>{t('segments_benchmark_settings__title')}</Typography.H4>
      <Typography.P>{t('segments_benchmark_settings__info')}</Typography.P>
      <SegmentBenchmarkForm onSubmit={handleSegmentAdded} />
      {!isLoading && segments.length > 0 && (
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>{t('segments_benchmark_settings__segment')}</th>
                <th>{t('segments_benchmark_settings__benchmark')}</th>
                <th>{t('company_benchmark_settings__propagated')}</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {segments.map((segment) => {
                const {
                  benchmarkType,
                  benchmarkId,
                  benchmarkPropagate,
                  benchmarkSelector,
                } = segment.attributes;
                let percentile;
                if ('percentile' in benchmarkSelector) {
                  percentile = benchmarkSelector.percentile;
                }

                if (!benchmarkType) {
                  return null;
                }

                /*
                 * For cyclical benchmarks, e.g. Segment A -> Segment B and Segment B -> segment A
                 * We only need information about Segment A.relationships.benchmark(Segment B),
                 * not Segment A.relationships.benchmark(Segment B).relationships.benchmark(Segment A)
                 * so we remove the nested benchmark relationship
                 * as not to cause Maximum call stack size exceeded when calling Segment.createFromApi(segment / benchmark)
                 */
                let benchmark;
                if ('benchmark' in segment.relationships) {
                  benchmark = segment.relationships.benchmark;
                }
                if (
                  benchmark &&
                  benchmark.relationships &&
                  benchmark.relationships.benchmark
                ) {
                  benchmark.relationships.benchmark = undefined;
                }

                return (
                  <tr key={segment.id} className={styles.segment}>
                    <td>
                      <ContextLabel
                        avatarSize="tiny"
                        // @ts-expect-error TS(2339): Types of property 'attributes' are incompatible.
                        dashboardContext={Segment.createFromApi(segment)}
                        modifier="list"
                      />
                    </td>
                    <td>
                      {benchmarkType === 'context' ? (
                        'benchmark' in segment.relationships && (
                          <BenchmarkLabel
                            id={benchmarkId}
                            benchmark={Segment.createFromApi(
                              // @ts-expect-error TS(2339): Types of property 'attributes' are incompatible.
                              segment.relationships.benchmark,
                            )}
                            percentile={undefined}
                            type={benchmarkType}
                          />
                        )
                      ) : (
                        <BenchmarkLabel
                          id={benchmarkId}
                          benchmark={undefined}
                          percentile={percentile}
                          type={benchmarkType}
                        />
                      )}
                    </td>
                    <td>{benchmarkPropagate ? t('yes') : t('no')}</td>
                    <td className={styles.actions}>
                      <Button
                        busy={isLoadingRemoval && removedId === segment.id}
                        variant="danger"
                        onClick={() => handleRemove(segment.id)}
                        size="small"
                      >
                        {t('segments_benchmark_settings__remove')}
                      </Button>
                    </td>
                  </tr>
                );
              })}
              {hasNextPage && (
                <tr>
                  <td colSpan={4}>
                    <InView
                      onChange={(inView) => inView && fetchNextPage()}
                      className={styles.loader}
                    >
                      <Spinner />
                    </InView>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
