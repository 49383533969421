import ErrorReporter from '@peakon/error-reporter';

import env from './env';

const getErrorReporter = () => {
  return new ErrorReporter({
    accessToken: env.rollbar ? env.rollbar.client : '',
    environment: env.clusterEnv,
    version: env.version,
  });
};

const errorReporter = window.__peakon_error__ ?? getErrorReporter();
window.__peakon_error__ = errorReporter;

export type ErrorReporterType = ReturnType<typeof getErrorReporter>;

// eslint-disable-next-line import/no-default-export
export default errorReporter;
