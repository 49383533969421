import first from 'lodash/first';
import trim from 'lodash/trim';

import jsonapiparser from '@peakon/jsonapiparser';
import api from '@peakon/shared/utils/api';

import { showErrorNotification } from '../actions/NotificationActions';
import { currentContext } from '../selectors/ContextSelectors';
import { getDatasetParams } from '../selectors/SessionSelectors';
import fileService from '../services/FileService';
import { Dispatch, GetState } from '../types/redux';
import { asyncDispatch, errorReporter, extractMessage } from '../utils';

const PAGE_LIMIT = 100;

export const SEGMENT_FIELDS = [
  'abbreviation',
  'attribute',
  'contextId',
  'direct',
  'logo',
  'name',
  'nameTranslated',
  'rights',
  'type',
];

type CreateUrlParams = {
  driverId?: string;
  subdriverId?: string;
  valueId?: string;
};

export const createUrl = (
  baseUrl: string,
  { driverId, subdriverId, valueId }: CreateUrlParams = {},
) => {
  if (driverId && (driverId !== 'engagement' || subdriverId)) {
    // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
    baseUrl += `/drivers/${driverId}`;

    if (subdriverId) {
      // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
      baseUrl += `/${subdriverId}`;
    }
  }

  if (valueId) {
    // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
    baseUrl += `/values/${valueId}`;
  }

  return baseUrl;
};

export const searchHeatmap =
  (query: string, { optionOnly }: { optionOnly?: boolean } = {}) =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const context = currentContext(state);
    const datasetParams = getDatasetParams(state);

    const params = {
      q: trim(query),
      sort: 'relevance',
      order: 'desc',
      per_page: 10,
      include: 'critical,segment,segment.attribute',
      translated: true,
      categoryGroup: state?.heatmap?.filters?.group,
      ...datasetParams(),
    };

    if (optionOnly) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      params['filter[type]'] = 'option';
    }

    return asyncDispatch({
      dispatch,
      resource: 'SEGMENTS_LIST',
      data: { query },
      action: api
        .get(`/engagement/contexts/${context.id}/segments/v2`, params)
        .then(jsonapiparser),
    });
  };

export const search =
  (query: string, { optionOnly }: { optionOnly?: boolean } = {}) =>
  (dispatch: Dispatch) => {
    const params = {
      q: trim(query),
      sort: 'relevance',
      order: 'desc',
      per_page: 10,
      include: 'attribute',
      translated: true,
    };

    if (optionOnly) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      params['filter[type]'] = 'option';
    }

    return asyncDispatch({
      dispatch,
      resource: 'SEGMENTS_LIST',
      data: { query },
      action: api.segment.list(params),
    });
  };

type LoadAttributesForParams = {
  contextId: string;
  driverId: string;
  subdriverId: string;
  valueId: string;
};

export const loadAttributesFor =
  ({ contextId, driverId, subdriverId, valueId }: LoadAttributesForParams) =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const datasetParams = getDatasetParams(state);

    const url = createUrl(`/engagement/contexts/${contextId}/segments`, {
      driverId,
      subdriverId,
      valueId,
    });

    return asyncDispatch({
      dispatch,
      resource: 'SEGMENTS_READ',
      data: { contextId, driverId, subdriverId, valueId },
      action: api
        .get(
          url,
          datasetParams({
            include: 'attribute',
            categoryGroup: state?.heatmap?.filters?.group,
          }),
        )
        .then(jsonapiparser),
    });
  };

type GetChildSegmentsParams = {
  contextId: string;
  perPage: number;
};

export const getChildSegments =
  ({ contextId, perPage }: GetChildSegmentsParams) =>
  (dispatch: Dispatch) => {
    return asyncDispatch({
      dispatch,
      resource: 'CHILD_SEGMENTS_READ',
      data: { contextId },
      action: api
        .get(`/engagement/contexts/${contextId}/links`, {
          include: 'critical,segment,segment.attribute',
          per_page: perPage,
        })
        .then(jsonapiparser),
    });
  };

export const loadSegmentGroups =
  (params: { [x: string]: $TSFixMe }) => (dispatch: Dispatch) => {
    return asyncDispatch({
      dispatch,
      resource: 'SEGMENT_GROUPS_GET',
      action: api
        .get(`/segments/groups`, {
          ...params,
          include: 'attribute',
        })
        .then(jsonapiparser),
    });
  };

export const loadSegments =
  (id: string, { includeHighlights, valueId, ...params }: $TSFixMe = {}) =>
  (dispatch: Dispatch) => {
    if (id === 'link') {
      return dispatch(getChildSegments(params));
    } else if (id === 'critical') {
      return dispatch(getCriticalSegmentsForContext(params));
    }

    if (includeHighlights) {
      return dispatch(
        getEngagementAttributeSegments(params.contextId, id, {
          valueId,
          ...params,
        }),
      );
    }

    return dispatch(getAttributeSegments(id));
  };

const lazyPromiseChain = async (nextUrl: string) => {
  const data = [];
  let hasError = false;

  while (nextUrl && !hasError) {
    try {
      const response = await api.get(nextUrl);
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
      nextUrl = response.links.next;

      data.push(jsonapiparser(response));
    } catch {
      hasError = true;

      // added to monitor the error rate when rolling out
      // the lazy pagination
      errorReporter.warning(new Error(`Error paginating segments`));
    }
  }

  return data;
};

const getLazyPaginated = (url: string, params: { [x: string]: $TSFixMe }) => {
  let responses: $TSFixMe = [];

  return new Promise((resolve, reject) => {
    return api
      .get(url, params)
      .then(jsonapiparser)
      .then(async (response) => {
        responses.push(response);

        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        if (response.links.next) {
          // @ts-expect-error TS(2571): Object is of type 'unknown'.
          const pages = await lazyPromiseChain(response.links.next);

          responses = responses.concat(pages);
        }

        return resolve(
          responses.reduce(
            // @ts-expect-error TS(7006): Parameter 'acc' implicitly has an 'any' type.
            (acc, curr) => {
              return {
                data: curr.data ? acc.data.concat(curr.data) : acc.data,
                included: curr.included
                  ? acc.included.concat(curr.included)
                  : acc.included,
                meta: curr.meta || acc.meta,
              };
            },
            { data: [], included: [], meta: {} },
          ),
        );
      })
      .catch(reject);
  });
};

export const getAttributeSegments =
  (attributeId: string) => (dispatch: Dispatch) => {
    return asyncDispatch({
      dispatch,
      data: { attributeId },
      resource: 'SEGMENT_ATTRIBUTES_GET',
      action: getLazyPaginated(`/segments/attributes/${attributeId}`, {
        fields: {
          segments: [
            ...SEGMENT_FIELDS,
            'employee',
            'employeeCount',
            'identifier',
          ].join(','),
          employees: 'identifier,account',
          accounts: 'email',
        },

        include: 'attribute,segment,employee,employee.account',
        per_page: PAGE_LIMIT,
      }),
    });
  };

type GetEngagementAttributeSegmentsParams = {
  driverId?: string;
  subdriverId?: string;
  valueId?: string;
};

export const getEngagementAttributeSegments =
  (
    contextId: string,
    attributeId: string,
    {
      driverId,
      subdriverId,
      valueId,
    }: GetEngagementAttributeSegmentsParams = {},
  ) =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const datasetParams = getDatasetParams(state);

    const url = createUrl(
      `/engagement/contexts/${contextId}/segments/v2/attributes/${attributeId}`,
      {
        driverId,
        subdriverId,
        valueId,
      },
    );

    return asyncDispatch({
      dispatch,
      resource: 'SEGMENT_ATTRIBUTES_GET',
      data: { contextId, attributeId },
      action: getLazyPaginated(
        url,
        datasetParams({
          include: 'critical,segment,segment.attribute',
          categoryGroup: state?.heatmap?.filters?.group,
          per_page: PAGE_LIMIT,
        }),
      ),
    });
  };

type GetCriticalSegmentsForContextParams = {
  contextId?: string;
  group?: $TSFixMe;
  driverId?: string;
  subdriverId?: string;
  valueId?: string;
  shouldPreserveHeatmapState?: boolean;
  isInitialLoad?: boolean;
};

export const getCriticalSegmentsForContext =
  (
    {
      contextId,
      group,
      driverId,
      subdriverId,
      valueId,
      shouldPreserveHeatmapState,
      isInitialLoad,
    }: GetCriticalSegmentsForContextParams,
    { interval }: $TSFixMe = {},
  ) =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();

    // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
    contextId = contextId || currentContext(state).id;
    const datasetParams = getDatasetParams(state);

    const url = createUrl(
      group
        ? `/scores/contexts/${contextId}/group/${group}/critical_segments`
        : `/engagement/contexts/${contextId}/segments/v2/critical`,
      {
        driverId,
        subdriverId,
        valueId,
      },
    );

    return asyncDispatch({
      dispatch,
      resource: 'CRITICAL_SEGMENTS_READ',
      data: {
        contextId,
        driverId,
        subdriverId,
        shouldPreserveHeatmapState,
        isInitialLoad,
      },
      showNotification: false,
      action: api
        .get(
          url,
          datasetParams({
            include: 'critical,segment,segment.attribute',
            interval,
          }),
        )
        .then(jsonapiparser),
    }).catch((error) => {
      // we expect 403 errors when users have partial access without an outcome
      if (error && error.code !== 403) {
        dispatch(
          showErrorNotification({
            ...extractMessage(error),
            code: error.status,
          }),
        );
        throw error;
      }
    });
  };

type GetByContextParams = {
  contextId: string;
  categoryId: string;
  segmentId: string;
  driverId: string;
  subdriverId: string;
};

export const getByContext =
  ({
    contextId,
    categoryId,
    segmentId,
    driverId,
    subdriverId,
  }: GetByContextParams) =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const { company } = state;
    const datasetParams = getDatasetParams(state);

    let baseUrl;

    if (categoryId) {
      baseUrl = `/scores/contexts/${contextId}/category/${categoryId}`;
    } else {
      baseUrl = `/engagement/contexts/${contextId}/drivers/v2/${driverId}`;

      if (subdriverId) {
        baseUrl += `/${subdriverId}`;
      }
    }

    return asyncDispatch({
      dispatch,
      resource: 'CONTEXT_SEGMENT_READ',
      data: { contextId },
      action: api
        .get(
          baseUrl,
          datasetParams({
            attrition: company.hasAddOn('attrition_prediction'),
            changes: true,
            filter: {
              'employee.segmentIds': `${segmentId}$contains`,
            },
          }),
        )
        .then((data) => {
          const parsedData = jsonapiparser(data);

          if (categoryId) {
            return {
              // @ts-expect-error return from jsonapiparser is unknown
              data: first(parsedData.data),
            };
          }

          return parsedData;
        }),
    });
  };

export const getSegmentLinks = (
  contextId: string,
  segmentId: string,
  params: { [x: string]: $TSFixMe },
) => {
  return api
    .get(
      `/engagement/contexts/${contextId}/segments/${segmentId}/links`,
      params,
    )
    .then(jsonapiparser);
};

export const loadSegmentById = (
  id: string,
  params?: { [x: string]: $TSFixMe },
) => {
  return api
    .get(`/segments/${id}`, {
      ...params,
      fields: {
        segments: SEGMENT_FIELDS.join(','),
      },

      include: 'attribute',
    })
    .then(jsonapiparser);
};

export const get = (id: string) => (dispatch: Dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'SEGMENT_READ',
    data: { id },
    action: loadSegmentById(id),
  });
};

export const uploadImportFile = (file: File) => (dispatch: Dispatch) =>
  asyncDispatch({
    dispatch,
    resource: 'SEGMENT_MANAGERS_UPLOAD',
    action: fileService.upload(file, 'managers', {
      isImage: false,
    }),
  });

export const importManagers =
  (attributeId: string, file: $TSFixMe, { removeOthers = false } = {}) =>
  (dispatch: Dispatch) =>
    asyncDispatch({
      dispatch,
      resource: 'SEGMENT_MANAGERS_IMPORT',
      data: { attributeId },
      action: api.post(`/segments/attributes/${attributeId}/managers/import`, {
        file,
        removeOthers,
      }),
    });
