import { z } from 'zod';

import errorReporter from './errorReporter';

/** Logs Zod validation error to `errorReporter` wrapped within a `console.group` locally  */
export function reportZodError({
  error,
  errorMessagePrefix,
  data,
  logLevel = 'error',
}: {
  data: unknown;
  error: z.ZodError;
  errorMessagePrefix: string;
  logLevel?: 'info' | 'error' | 'warning';
}) {
  const isLocal = !ENV.clusterEnv;
  if (isLocal) {
    // eslint-disable-next-line no-console
    console.group('Zod Validation Error');
  }
  errorReporter[logLevel](
    new Error(`${errorMessagePrefix}: ${error.message}`),
    ENV.clusterEnv === 'staging'
      ? {
          data,
        }
      : undefined,
  );

  if (isLocal) {
    // eslint-disable-next-line no-console
    console.info('data', data);
    // eslint-disable-next-line no-console
    console.groupEnd();
  }
}
