import React from 'react';

import classnames from 'classnames';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Tooltip } from 'react-tippy';

import { EditRepeatCircleIcon as AutomaticIcon } from '@peakon/bedrock/icons/system';
import { Ellipsis } from '@peakon/components';
import { Employee } from '@peakon/records';
import { AsyncButton } from '@peakon/shared/components/AsyncButton/AsyncButton';
import { t } from '@peakon/shared/features/i18next/t';

import styles from './styles.css';

type Props = {
  automatic?: boolean;
  employee: Employee;
  level?: number;
  onRemove?: (id: string) => Promise<void>;
};

export const ManagerInfo = ({
  automatic,
  employee,
  level,
  onRemove,
}: Props) => (
  <div className={styles.root} data-test-id="slide-in-segment-manager-row">
    <div
      className={classnames(styles.avatar, {
        [styles.automatic]: automatic,
      })}
    >
      {automatic ? <AutomaticIcon /> : 'M'}
    </div>
    <div className={styles.nameContainer}>
      <div className={styles.name}>
        <Ellipsis testId="slide-in-segment-manager-name">
          {employee.name}
        </Ellipsis>
      </div>
      {!employee.isAnonymized && (
        <div className={styles.details}>
          <div className={styles.email}>
            <Ellipsis>
              {employee.email ||
                (employee.identifier ? `(${employee.identifier})` : '')}
            </Ellipsis>
          </div>
        </div>
      )}
    </div>
    <div>
      {automatic || level !== 0 ? (
        <Tooltip
          animateFill={false}
          animation="shift"
          arrow
          position="left"
          html={
            <div>
              {t('segment_managers_modal__level_tooltip', {
                ns: 'temporary',
                replace: {
                  level,
                },
              })}
            </div>
          }
        >
          <div className={styles.level}>
            {t('segment_managers_modal__level', { replace: { level } })}
          </div>
        </Tooltip>
      ) : (
        <AsyncButton
          // @ts-expect-error id should be a string
          onClick={() => onRemove?.(employee.id)}
          variant="secondary"
          size="small"
        >
          {t('segment_managers_modal__delete')}
        </AsyncButton>
      )}
    </div>
  </div>
);
