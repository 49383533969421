import z from 'zod';

import { TypeEnum, AccessEnum } from './attributes';
import { translatedStringSchema } from '../../features/i18next/t';

const percentile = z.union([
  z.literal(95),
  z.literal(90),
  z.literal(75),
  z.literal(50),
  z.literal(25),
  z.literal(10),
  z.literal(5),
]);

const baseSegmentAttributes = z.object({
  abbreviation: z.string(),
  benchmarkPropagate: z.boolean(),
  contextId: z.string(),
  direct: z.boolean(),
  identifier: z.string(),
  logo: z.string().nullable(),
  name: z.string(),
  nameTranslated: translatedStringSchema,
  standard: z.string().nullable(),
  type: TypeEnum,
});

const contextBenchmarkSegmentAttributes = baseSegmentAttributes.extend({
  benchmarkType: z.literal('context'),
  benchmarkId: z.string(),
  benchmarkSelector: z.object({
    id: z.string(),
  }),
});

const overallBenchmarkSegmentAttributes = baseSegmentAttributes.extend({
  benchmarkType: z.literal('overall'),
  benchmarkId: z.literal('peakon'),
  benchmarkSelector: z.object({
    percentile: percentile.nullable().optional(),
  }),
});

const industryBenchmarkSegmentAttributes = baseSegmentAttributes.extend({
  benchmarkType: z.literal('industry'),
  benchmarkId: z.string(),
  benchmarkSelector: z.object({
    sector: z.string(),
    percentile: percentile.nullable().optional(),
  }),
});

const nullBenchmarkSegmentAttributes = baseSegmentAttributes.extend({
  benchmarkType: z.null(),
  benchmarkId: z.null(),
  benchmarkSelector: z.object({}).nullable(),
});

const segmentAttributes = z.discriminatedUnion('benchmarkType', [
  contextBenchmarkSegmentAttributes,
  overallBenchmarkSegmentAttributes,
  industryBenchmarkSegmentAttributes,
]);

const attribute = z.object({
  id: z.string(),
  type: z.literal('attributes'),
  attributes: z.object({
    comparisonAccess: AccessEnum,
    comparisonAccessLocked: z.boolean(),
    description: z.string().nullable(),
    descriptionTranslated: translatedStringSchema.nullable(),
    employeeAccess: AccessEnum,
    name: z.string(),
    nameTranslated: translatedStringSchema,
    sensitive: z.boolean(),
    standard: z.string().nullable(),
    type: TypeEnum,
  }),
  links: z.object({
    self: z.string().url(),
  }),
});

const benchmark = z
  .object({
    id: z.string(),
    type: z.literal('segments'),
    attributes: z.discriminatedUnion('benchmarkType', [
      ...segmentAttributes._def.options,
      nullBenchmarkSegmentAttributes,
    ]),
    links: z.object({
      self: z.string().url(),
    }),
    relationships: z
      .object({
        attribute,
        benchmark: z.object({}).passthrough().nullable().optional(),
      })
      .optional(),
  })
  .optional();

export const segment = z
  .object({
    id: z.string(),
    type: z.literal('segments'),
    attributes: segmentAttributes,
    links: z.object({
      self: z.string().url(),
    }),
    relationships: z.object({ attribute, benchmark }),
  })
  .refine(
    (data) => {
      if (['overall', 'industry'].includes(data.attributes.benchmarkType)) {
        // If  benchmarkType is 'overall' or 'industry', benchmark should not exist
        return data.relationships && data.relationships.benchmark === undefined;
      } else {
        // If not, benchmark should exist
        return data.relationships && data.relationships.benchmark !== undefined;
      }
    },
    {
      message:
        'relationships.benchmark should only exist if benchmarkType is overall or industry',
      path: ['relationships', 'benchmark'],
    },
  );
