import { Record as ImmutableRecord, fromJS, List, Map, Set } from 'immutable';
import every from 'lodash/every';
import first from 'lodash/first';
import get from 'lodash/get';
import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import reduce from 'lodash/reduce';
import moment from 'moment';
import { z } from 'zod';

import { TimezoneValue } from '@peakon/shared/data/timezones';
import { AccountResponse } from '@peakon/shared/schemas/api/accounts';
import { AttributeOptionResponse } from '@peakon/shared/schemas/api/attributes';
import { EmployeeResponse } from '@peakon/shared/schemas/api/employees';
import { EmployeeTypes, Source } from '@peakon/shared/types/Employee';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import Attribute, { AttributeOption } from './AttributeRecord';
import SegmentManager from './SegmentManagerRecord';
import { validateTestingSchema } from './utils';

export const DEFAULT_FEATURES = Map({
  engagement: false,
});

export const EMPLOYEE_KEYS = {
  accessLevels: undefined,
  attributes: Map(),
  employeeAttributes: Map(),
  optionAttributes: Map(),
  avatar: undefined,
  features: DEFAULT_FEATURES,
  firstName: undefined,
  id: undefined,
  externalId: undefined,
  source: undefined,
  type: undefined,
  identifier: undefined,
  kioskCode: undefined,
  lastName: undefined,
  meta: undefined,
  name: undefined,
  phone: undefined,
  reportCount: undefined,
  createdAt: undefined,
  invitedToAdministerAt: undefined,
  isAnonymized: undefined,
  managedSegments: undefined,

  // account properties, to be grouped in account: new Account()
  accountId: undefined,
  bouncedAt: undefined,
  bounceReason: undefined,
  email: undefined,
  locale: undefined,
  localeEffective: undefined,
  timezone: undefined,
  timezoneEffective: undefined,
  lastSeenAt: undefined,

  placeholder: false,
};

export const employeeSchema = z.object({
  placeholder: z.boolean().optional(),
  id: z.string().optional(),
});
const testingEmployeeSchema = employeeSchema.extend({
  name: z.any(),
  features: z.any(),
  attributes: z.any(),
  lastName: z.any(),
  lastSeenAt: z.any(),
  employeeAttributes: z.any(),

  identifier: z.any(),
  bounceReason: z.any(),
  optionAttributes: z.any(),
  localeEffective: z.any(),
  phone: z.any(),
  timezoneEffective: z.any(),
  bouncedAt: z.any(),
  invitedToAdministerAt: z.any(),
  accessLevels: z.any(),
  kioskCode: z.any(),
  avatar: z.any(),
  timezone: z.any(),
  isAnonymized: z.any(),
  locale: z.any(),
  type: z.any(),
  source: z.any(),
  reportCount: z.any(),
  createdAt: z.any(),
  firstName: z.any(),
  email: z.any(),
  managedSegments: z.any(),

  // seems like a typo somewhere
  externalid: z.string().optional(),
  accountid: z.string().optional(),
  externalId: z.any().optional(),
  accountId: z.string().optional(),
});

// @ts-expect-error not used yet
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type Schema = z.infer<typeof employeeSchema>;

// eslint-disable-next-line import/no-default-export
export default class Employee extends ImmutableRecord(EMPLOYEE_KEYS) {
  id?: string;
  accessLevels?: List<string>;
  accountId?: string;
  attributes!: Map<string, string | number | null>;
  avatar?: string;
  bouncedAt?: string;
  bounceReason?: string;
  createdAt?: string;
  email?: string;
  employeeAttributes!: Map<string, string | number | null>;
  externalId?: string;
  features!: Map<string, boolean>;
  firstName?: string;
  identifier?: string;
  invitedToAdministerAt?: string;
  isAnonymized?: boolean;
  kioskCode?: string;
  lastName?: string;
  lastSeenAt?: string;
  locale?: string;
  localeEffective?: string;
  managedSegments?: SegmentManager[];
  // FIXME: is this used?
  meta?: Record<string, unknown>;
  name!: string;
  optionAttributes!: Map<string, unknown>;
  phone?: string;
  placeholder!: boolean;
  reportCount?: number;
  source?: Source;
  timezone?: TimezoneValue;
  timezoneEffective?: TimezoneValue;
  type?: EmployeeTypes;

  constructor(props: unknown = {}) {
    validateRecord(props, employeeSchema, {
      errorMessagePrefix: 'Employee',
    });
    validateTestingSchema(props, testingEmployeeSchema, {
      errorMessagePrefix: 'Employee',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  get abbreviation() {
    return (first(this.firstName) || '') + (first(this.lastName) || '');
  }

  isEngaged() {
    return this.features.get('engagement');
  }

  canBeNotified() {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const employmentStatus = this.attributes.get('employmentStatus') as string;
    return !['hired', 'left'].includes(employmentStatus);
  }

  isBounced() {
    return Boolean(this.bouncedAt || this.bounceReason);
  }

  isBasicAccess() {
    return (
      this.accessLevels &&
      this.accessLevels.size === 1 &&
      this.accessLevels.first() === 'basic'
    );
  }

  isLeaver() {
    const attributes = this.get('attributes');
    const employmentStatus = attributes.get('employmentStatus');

    if (employmentStatus !== 'left') {
      return false;
    }

    const employmentEnd = attributes.get('employmentEnd');
    const leaverDate = moment(employmentEnd);

    return leaverDate.diff(new Date(), 'day') < 0;
  }

  static getAccount(account: AccountResponse) {
    const bouncedAt = account?.attributes?.bouncedAt;
    const lastSeenAt = account?.attributes?.lastSeenAt;
    return {
      accountId: account?.id,
      bouncedAt: bouncedAt ? new Date(bouncedAt) : undefined,
      bounceReason: account?.attributes?.bounceReason,
      email: account?.attributes?.email,
      locale: account?.attributes?.locale,
      localeEffective: account?.attributes?.localeEffective,
      timezone: account?.attributes?.timezone,
      timezoneEffective: account?.attributes?.timezoneEffective,
      lastSeenAt: lastSeenAt ? new Date(lastSeenAt) : undefined,
    };
  }

  static createFromApi(data: EmployeeResponse) {
    const {
      id,
      attributes: {
        accessLevels,
        avatar,
        employmentStart: _employmentStart,
        external: _external,
        features,
        firstName,
        identifier,
        externalId,
        source,
        kioskCode,
        lastName,
        name,
        reportCount,
        createdAt,
        invitedToAdministerAt,
        type,
        isAnonymized,
        ...other
      } = {},
      relationships: {
        account,
        phones = [],
        managedSegments,
        ...otherRelationships
      } = {},
    } = data;

    const employeeAttributes = Object.keys(otherRelationships)
      .filter((key) => {
        const otherRelationshipsType = get(otherRelationships, `${key}.type`);
        return otherRelationshipsType === 'employees';
      })
      .reduce((acc, relationshipsName) => {
        const relationshipsId = get(
          otherRelationships,
          `${relationshipsName}.id`,
        );
        const attrs = get(
          otherRelationships,
          `${relationshipsName}.attributes`,
          {},
        );
        const employeeAccount = Employee.getAccount(
          // @ts-expect-error TS2345: Argument of type '{}' is not assignable to parameter of type '{ attributes:...
          get(
            otherRelationships,
            `${relationshipsName}.relationships.account`,
            {},
          ),
        );

        return acc.set(
          relationshipsName,
          Map({ id: relationshipsId, ...attrs, ...employeeAccount }),
        );
      }, Map());

    const optionAttributes = reduce(
      otherRelationships,
      (result, value: AttributeOptionResponse, key) =>
        value && value.attributes && value.type === 'attribute_options'
          ? result.set(
              key,
              new AttributeOption({
                id: value.id,
                title: value.attributes.name,
                titleTranslated: value.attributes.nameTranslated,
              }),
            )
          : result,
      Map(),
    );

    const featureValues = (features || []).reduce(
      (acc: Map<string, boolean>, current: string) => {
        // @ts-expect-error TS2339
        if (current === 'engagement' && other.employmentStatus === 'left') {
          return acc.set(current, false);
        }
        return acc.set(current, true);
      },
      DEFAULT_FEATURES,
    );

    const phone = first(
      (phones || [])
        .filter(
          (phoneItem) =>
            phoneItem.attributes && get(phoneItem, 'attributes.primary'),
        )
        .map((phoneItem) => get(phoneItem, 'attributes.number')),
    );

    const props = fromJS({
      id,
      identifier: identifier || undefined,
      externalId,
      source,
      kioskCode,
      firstName,
      lastName,
      avatar,
      name,
      phone,
      reportCount,
      createdAt,
      features: featureValues,
      employeeAttributes,
      invitedToAdministerAt,
      type,
      isAnonymized,
      optionAttributes,
      accessLevels,
      managedSegments,
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      ...Employee.getAccount(account as AccountResponse),
      attributes: {
        ...other,
      },
    });

    return new Employee(props);
  }

  toJsonApiWithAttributes(attributes: Map<string, Attribute>) {
    const features = this.features.toJS();

    const result = merge(
      this.toJsonApi(),
      merge(
        attributes
          .map((attribute) => attribute?.getJsonApiValue())
          .toArray()
          .reduce((curr, prev) => merge(curr, prev), {}),
        {
          attributes: {
            features,
          },
        },
      ),
    );

    return pickBy(
      // Remove features: {} if both are unchanged
      isEmpty(features) || every(features, isUndefined)
        ? omit(result, ['attributes.features'])
        : result,
      identity,
    );
  }

  toJsonApiBulk(attributes: List<Attribute>, fields: Set<string>) {
    let features = this.features
      .filter((_value, key) => Boolean(key && fields.includes(key)))
      .toJS();

    if (isEmpty(features)) {
      features = undefined;
    }

    let timezone;
    let locale;
    if (fields.includes('timezone')) {
      timezone = this.timezone;
    }

    if (fields.includes('locale')) {
      locale = this.locale;
    }

    return merge(
      {
        type: 'employees',
      },
      merge(
        attributes
          .filter((attribute) =>
            Boolean(attribute && fields.includes(attribute.id)),
          )
          .map((attribute) => attribute?.getJsonApiValue())
          .toArray()
          .reduce((curr, prev) => merge(curr, prev), {}),
        { attributes: { features, timezone, locale } },
      ),
    );
  }

  phoneToJsonApi() {
    if (typeof this.phone === 'undefined') {
      return {};
    } else if (this.phone === null || this.phone === '') {
      return {
        phones: {
          data: [],
        },
      };
    }

    return {
      phones: {
        data: [
          { type: 'phones', attributes: { number: this.phone, primary: true } },
        ],
      },
    };
  }

  toJsonApi() {
    const {
      id,
      meta: _meta,
      email,
      phone: _phone,
      locale,
      timezone,
      features,
      placeholder: _placeholder,
      employeeAttributes: _employeeAttributes,
      optionAttributes: _optionAttributes,
      identifier,
      attributes: _attributes,
      ...attributes
      // @ts-expect-error Property 'toJSON' does not exist on type 'Employee'. Did you mean 'toJS'?ts(2551)
    } = this.toJSON();

    return {
      type: 'employees',
      id,
      attributes: {
        email: email === '' ? null : email,
        features,
        locale,
        timezone,
        identifier: identifier === '' ? null : identifier,
        ...attributes,
      },
      relationships: { ...this.phoneToJsonApi() },
    };
  }
}
