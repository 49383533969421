import React from 'react';

import { Segment } from '@peakon/records';
import { createTranslationMap } from '@peakon/shared/features/i18next/createTranslationMap';
import { t } from '@peakon/shared/features/i18next/t';
import { BenchmarkType } from '@peakon/shared/types/Benchmark';

import ContextLabel from '../../../ContextLabel';
import { Percentile } from '../PercentileSelector/PercentileSelector';

import styles from './styles.css';

type BenchmarkLabelProps =
  | {
      id: string;
      benchmark: Segment;
      percentile: undefined | null;
      type: 'context';
    }
  | {
      id: string;
      benchmark: undefined;
      percentile: Percentile | undefined | null;
      type: 'overall' | 'industry';
    };

export const useBenchmarkTypeTranslationMap = () => {
  const benchmarkTypeTranslationMap = createTranslationMap({
    automatic: t('benchmark_type__automatic'),
    overall: t('benchmark_type__overall'),
    industry: t('benchmark_type__industry'),
    context: t('benchmark_type__context'),
  } as const satisfies Record<NonNullable<BenchmarkType>, string>);

  return { benchmarkTypeTranslationMap };
};

const getLabel = (percentile: Percentile) => {
  const percentage = percentile > 50 ? 100 - percentile : percentile;
  if (percentile === 50) {
    return t('dashboard_settings__engagement_score__average', {
      replace: { percentage },
    });
  }
  if (percentile < 50) {
    return t('dashboard__benchmark__bottom', { replace: { percentage } });
  }
  return t('dashboard__benchmark__top', { replace: { percentage } });
};

export const BenchmarkLabel = ({
  id,
  benchmark,
  percentile,
  type,
}: BenchmarkLabelProps) => {
  const { benchmarkTypeTranslationMap } = useBenchmarkTypeTranslationMap();
  const percentileLabel = percentile && <span> - {getLabel(percentile)}</span>;

  return (
    <div className={styles.root}>
      {type !== 'context' && (
        <div className={styles.type}>
          {benchmarkTypeTranslationMap.get(type)}
          {type !== 'overall' && <span>:</span>}
        </div>
      )}
      {type === 'overall' && percentileLabel}
      {type === 'industry' && (
        <div>
          {id}
          {percentileLabel}
        </div>
      )}
      {type === 'context' && (
        <ContextLabel
          avatarSize="tiny"
          dashboardContext={benchmark}
          modifier="list"
        />
      )}
    </div>
  );
};
