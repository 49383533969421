import merge from 'lodash/merge';

import ErrorReporter from '@peakon/error-reporter';

import env from './env';
import { getBuffer } from './logMiddleware';

const transform = (payload: $TSFixMe) =>
  merge(payload, {
    data: {
      custom: {
        previousActions: getBuffer(),
      },
    },
  });

const getErrorReporter = () => {
  return new ErrorReporter({
    accessToken: env.rollbar ? env.rollbar.client : '',
    environment: env.clusterEnv,
    version: env.version,
    transform,
  });
};

// install rollbar into window.error
const errorReporter = window.__peakon_error__ ?? getErrorReporter();
window.__peakon_error__ = errorReporter;

// eslint-disable-next-line import/no-default-export
export default errorReporter;
