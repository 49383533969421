import { useMutation, useQueryClient } from '@tanstack/react-query';

import jsonapiparser from '@peakon/jsonapiparser';
import api from '@peakon/shared/utils/api';

import { queryKeys } from './queryKeys';

const updateSegment = async (segmentId: string): Promise<string> => {
  const response = await api.patch(`/segments/${segmentId}`, null, {
    data: {
      type: 'segments',
      attributes: {
        benchmarkId: null,
        benchmarkType: null,
      },
    },
  });
  // @ts-expect-error return from jsonapiparser is unknown
  const { id } = jsonapiparser(response).data;
  return id;
};

export const useUpdateSegmentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateSegment,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: queryKeys.segments() }),
  });
};
