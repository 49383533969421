import { AccessGroupLevel } from './AccessGroupRecord';
import {
  CORE_QUESTION_SETS,
  ALL_QUESTION_SETS,
  CategoryGroup,
} from './constants/questionSets';

type AccessSettingGroups =
  | 'statistics'
  | 'general'
  | 'engage'
  | 'employeeDashboard';

export type AccessSettingDisables = 'admin' | 'manager' | 'all';

export type AccessByGroup = Partial<
  Record<
    CategoryGroup,
    'full' | 'restricted' | 'partial' | 'partialOverview' | null
  >
>;

type AccessCompanySettings = {
  semanticSearchTerms: boolean;
};

type AccessSettingGroupProps = {
  group: AccessSettingGroups;
  parents?: string[];
  disables?: AccessSettingDisables[];
  levels: AccessGroupLevel[];
  feature?: string;
  featureFlag?: string;
  addOn?: string;
  requiresCategoryGroup?: CategoryGroup[];
  partialGroupAccess?: boolean;
  requiresToEnable?: Record<string, unknown>;
  companySettings?: AccessCompanySettings;
};

export class AccessSettingGroup {
  companySettings?: AccessCompanySettings;
  group: AccessSettingGroups;
  parents?: string[];
  disables?: AccessSettingDisables[];
  levels: AccessGroupLevel[];
  feature?: string;
  featureFlag?: string;
  addOn?: string;
  requiresCategoryGroup?: CategoryGroup[];
  partialGroupAccess?: boolean;
  requiresToEnable?: Record<string, unknown>;

  constructor({
    group,
    parents,
    disables,
    levels,
    feature,
    featureFlag,
    addOn,
    requiresCategoryGroup,
    partialGroupAccess,
    companySettings,
    requiresToEnable,
  }: AccessSettingGroupProps) {
    this.group = group;
    this.parents = parents;
    this.disables = disables;
    this.levels = levels;
    this.feature = feature;
    this.featureFlag = featureFlag;
    this.addOn = addOn;
    this.requiresCategoryGroup = requiresCategoryGroup;
    this.partialGroupAccess = partialGroupAccess;
    this.companySettings = companySettings;
    this.requiresToEnable = requiresToEnable;
  }

  hasParent(parent: string) {
    return typeof this.parents !== 'undefined' && this.parents.includes(parent);
  }

  hasCategoryGroup(categoryGroup: CategoryGroup) {
    return (
      typeof this.requiresCategoryGroup !== 'undefined' &&
      this.requiresCategoryGroup.includes(categoryGroup)
    );
  }

  hasFullAccess(accessByGroup: AccessByGroup) {
    if (typeof this.requiresCategoryGroup === 'undefined') {
      return true;
    }

    return this.requiresCategoryGroup?.some((group: CategoryGroup) => {
      return accessByGroup[group] === 'full';
    });
  }

  hasPartialAccess(accessByGroup: AccessByGroup) {
    if (this.partialGroupAccess) {
      return this.requiresCategoryGroup?.some((group: CategoryGroup) => {
        const access = accessByGroup[group];

        return access && access.startsWith('partial');
      });
    }

    return false;
  }

  hasGroupAccess(accessByGroup: AccessByGroup) {
    return (
      this.hasFullAccess(accessByGroup) || this.hasPartialAccess(accessByGroup)
    );
  }

  isDisabled(standard: AccessSettingDisables) {
    return (
      typeof this.disables !== 'undefined' && this.disables.includes(standard)
    );
  }

  isValidLevel(level: AccessGroupLevel) {
    return typeof this.levels === 'undefined' || this.levels.includes(level);
  }
}

export const accessSettingGroupKeys = [
  'attrition',
  'dataset',
  'datasetCompare',
  'datasetEmployees',
  'datasetTest',
  'datasetRestrictedAttributes',
  'priorityRead',
  'priorityAdmin',
  'readActions',
  'adminActions',
  'readEmployees',
  'share',
  'readEmployeesRestricted',
  'writeEmployees',
  'backdateEmployees',
  'employeeKioskCodes',
  'segmentAccess',
  'adminEmployees',
  'segmentAdmin',
  'attributeAdmin',
  'questionAdmin',
  'scheduleAdmin',
  'valueAdmin',
  'comment',
  'commentOpen',
  'commentAcknowledge',
  'commentSegments',
  'valueComment',
  'valueCommentAcknowledge',
  'input',
  'inputAcknowledge',
  'commentDelete',
  'upcomingRound',
  'lowParticipation',
  'conversation',
  'commentSensitiveRead',
  'commentSensitiveMark',
  'sensitiveAdmin',
  'sensitiveNotify',
  'commentManagers',
  'semanticSearch',
  'training',
  'companyResourcesAdmin',
  'adminCompanyActions',
  'commentCompare',
  'usageMetrics',
  'questionScoresPage',
  'externalMetricRead',
  'externalMetricAdmin',
  'externalMetricScore',
  'employeeDashboardAccess',
  'employeeDashboardScoresCompany',
  'employeeDashboardScoresSegment',
  'employeeDashboardScoresHierarchy',
  'brandingAdmin',
  'curatedInsights',
  'sharePublic',
] as const;

export type AccessSettingGroupKey = (typeof accessSettingGroupKeys)[number];

/** accessSettingsGroupsData key:
 * group: name of group setting belongs to
 * parents: which settings need to be enabled for this setting to be enabled
 * disables: signifies when a status is enabled and cannot be disabled (usually admin)
 * levels: which access groups have access
 * feature: which company feature applies to. Valid options [feedback, engagement], feedback being deprecated.
 * addOn: what add on needs to be enabled for this setting to be available,
 * requiresCategoryGroup: which question sets need to be enabled for this setting to be enabled (only one is required)
 * featureFlag: what featureFlag needs to be enabled for this setting to be enabled
 * companySettings: what company settings and what value needs to be set for this setting to be available
 * requiresToEnable: what conditions are required to enable this toggle (all of the needs to be satisfied)
 *                   - it might be that addon is enabled, but we are missing some company setting that is being set
 *                     somewhere else and we are not able to enable this toggle until conditions here are satisfied
 *                     Currently it supports checking conditions of following: companySettings
 */
export const ACCESS_SETTINGS_GROUPS: Record<
  AccessSettingGroupKey,
  AccessSettingGroup
> = {
  attrition: new AccessSettingGroup({
    group: 'statistics',
    parents: ['dataset'],
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
    addOn: 'attrition_prediction',
    requiresCategoryGroup: ['engagement'],
  }),
  dataset: new AccessSettingGroup({
    group: 'statistics',
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
    feature: 'engagement',
    requiresCategoryGroup: [...ALL_QUESTION_SETS],
    partialGroupAccess: true,
  }),
  datasetCompare: new AccessSettingGroup({
    group: 'statistics',
    parents: ['dataset'],
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
    feature: 'engagement',
  }),
  datasetEmployees: new AccessSettingGroup({
    group: 'statistics',
    parents: ['dataset'],
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
    feature: 'engagement',
  }),
  datasetTest: new AccessSettingGroup({
    group: 'statistics',
    parents: ['dataset'],
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
  }),
  datasetRestrictedAttributes: new AccessSettingGroup({
    group: 'statistics',
    parents: ['datasetCompare'],
    levels: ['all', 'manager', 'context'],
    feature: 'engagement',
  }),
  priorityRead: new AccessSettingGroup({
    group: 'statistics',
    parents: ['dataset'],
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
  }),
  priorityAdmin: new AccessSettingGroup({
    group: 'statistics',
    parents: ['priorityRead'],
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
  }),
  readActions: new AccessSettingGroup({
    group: 'engage',
    parents: ['dataset'],
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
    feature: 'engagement',
    addOn: 'actions',
  }),
  adminActions: new AccessSettingGroup({
    group: 'engage',
    parents: ['readActions'],
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
    feature: 'engagement',
    addOn: 'actions',
  }),
  readEmployees: new AccessSettingGroup({
    group: 'general',
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
  }),
  share: new AccessSettingGroup({
    group: 'statistics',
    parents: ['dataset'],
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
    addOn: 'collaboration_shareable',
  }),
  readEmployeesRestricted: new AccessSettingGroup({
    group: 'general',
    parents: ['readEmployees'],
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
  }),
  writeEmployees: new AccessSettingGroup({
    group: 'general',
    parents: ['readEmployees'],
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
  }),
  backdateEmployees: new AccessSettingGroup({
    group: 'general',
    parents: ['writeEmployees'],
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
  }),
  employeeKioskCodes: new AccessSettingGroup({
    group: 'general',
    parents: ['readEmployees'],
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
  }),
  segmentAccess: new AccessSettingGroup({
    group: 'general',
    parents: ['adminEmployees'],
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
  }),
  adminEmployees: new AccessSettingGroup({
    group: 'general',
    parents: ['writeEmployees'],
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
  }),
  segmentAdmin: new AccessSettingGroup({
    group: 'general',
    parents: ['adminEmployees'],
    disables: ['admin'],
    levels: ['all'],
  }),
  attributeAdmin: new AccessSettingGroup({
    group: 'general',
    parents: ['segmentAdmin'],
    disables: ['admin'],
    levels: ['all'],
  }),
  questionAdmin: new AccessSettingGroup({
    group: 'engage',
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
    feature: 'engagement',
  }),
  scheduleAdmin: new AccessSettingGroup({
    group: 'engage',
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
    feature: 'engagement',
  }),
  valueAdmin: new AccessSettingGroup({
    group: 'engage',
    disables: ['admin'],
    levels: ['all'],
    feature: 'engagement',
    addOn: 'values',
  }),
  comment: new AccessSettingGroup({
    group: 'engage',
    parents: ['dataset'],
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
    feature: 'engagement',
  }),
  commentOpen: new AccessSettingGroup({
    group: 'engage',
    parents: ['comment'],
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
    feature: 'engagement',
  }),
  commentAcknowledge: new AccessSettingGroup({
    group: 'engage',
    parents: ['comment'],
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
    feature: 'engagement',
  }),
  commentSegments: new AccessSettingGroup({
    group: 'engage',
    parents: ['commentCompare', 'datasetRestrictedAttributes'],
    levels: ['all', 'context', 'manager'],
    addOn: 'comment_segments_export',
  }),
  valueComment: new AccessSettingGroup({
    group: 'engage',
    parents: ['dataset'],
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
    addOn: 'values',
    requiresCategoryGroup: ['engagement'],
  }),
  valueCommentAcknowledge: new AccessSettingGroup({
    group: 'engage',
    parents: ['valueComment'],
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
    addOn: 'values',
    requiresCategoryGroup: ['engagement'],
  }),
  input: new AccessSettingGroup({
    group: 'engage',
    parents: ['dataset'],
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
    feature: 'engagement',
  }),
  inputAcknowledge: new AccessSettingGroup({
    group: 'engage',
    parents: ['input'],
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
    feature: 'engagement',
  }),
  commentDelete: new AccessSettingGroup({
    group: 'engage',
    parents: ['dataset'],
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
  }),
  upcomingRound: new AccessSettingGroup({
    group: 'engage',
    levels: ['all', 'manager', 'context'],
    feature: 'engagement',
  }),
  lowParticipation: new AccessSettingGroup({
    group: 'engage',
    levels: ['all', 'manager', 'context'],
    feature: 'engagement',
  }),
  conversation: new AccessSettingGroup({
    group: 'general',
    parents: ['comment'],
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
  }),
  commentSensitiveRead: new AccessSettingGroup({
    group: 'engage',
    feature: 'engagement',
    parents: ['commentOpen'],
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
    addOn: 'sensitive_comments',
  }),
  commentSensitiveMark: new AccessSettingGroup({
    group: 'engage',
    feature: 'engagement',
    parents: ['commentSensitiveRead'],
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
    addOn: 'sensitive_comments',
  }),
  sensitiveAdmin: new AccessSettingGroup({
    group: 'engage',
    feature: 'engagement',
    disables: ['admin'],
    levels: ['all'],
    addOn: 'sensitive_comments',
  }),
  sensitiveNotify: new AccessSettingGroup({
    group: 'engage',
    feature: 'engagement',
    parents: ['commentSensitiveRead'],
    disables: ['manager'],
    levels: ['all'],
    addOn: 'sensitive_comments',
  }),
  commentManagers: new AccessSettingGroup({
    group: 'engage',
    parents: ['comment'],
    levels: ['all', 'manager', 'context'],
    feature: 'engagement',
  }),
  semanticSearch: new AccessSettingGroup({
    group: 'engage',
    parents: ['comment'],
    levels: ['all', 'manager', 'context'],
    addOn: 'semantic_search',
    requiresToEnable: {
      companySettings: {
        semanticSearchTerms: 'accepted',
      },
    },
  }),
  training: new AccessSettingGroup({
    group: 'engage',
    parents: ['dataset'],
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
    addOn: 'training',
    requiresCategoryGroup: ['engagement', 'diversity_inclusion'],
  }),
  companyResourcesAdmin: new AccessSettingGroup({
    group: 'engage',
    disables: ['admin'],
    levels: ['all'],
    addOn: 'company_resources',
    requiresCategoryGroup: ['engagement'],
  }),
  adminCompanyActions: new AccessSettingGroup({
    group: 'engage',
    disables: ['admin'],
    levels: ['all'],
    addOn: 'company_actions',
    requiresCategoryGroup: ['engagement'],
  }),
  commentCompare: new AccessSettingGroup({
    group: 'engage',
    parents: ['comment', 'datasetCompare'],
    levels: ['all', 'manager', 'context'],
    feature: 'engagement',
  }),
  usageMetrics: new AccessSettingGroup({
    group: 'statistics',
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
  }),
  questionScoresPage: new AccessSettingGroup({
    group: 'statistics',
    disables: ['admin'],
    parents: ['dataset'],
    levels: ['all', 'manager', 'context'],
    feature: 'engagement',
  }),
  externalMetricRead: new AccessSettingGroup({
    group: 'statistics',
    disables: ['admin'],
    levels: ['all'],
    addOn: 'external_metrics',
  }),
  externalMetricAdmin: new AccessSettingGroup({
    group: 'statistics',
    disables: ['admin'],
    parents: ['externalMetricRead'],
    levels: ['all'],
    addOn: 'external_metrics',
  }),
  externalMetricScore: new AccessSettingGroup({
    group: 'statistics',
    parents: ['datasetCompare'],
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
    addOn: 'external_metrics',
  }),
  employeeDashboardAccess: new AccessSettingGroup({
    group: 'employeeDashboard',
    disables: ['admin', 'all'],
    levels: ['overall'],
  }),
  employeeDashboardScoresCompany: new AccessSettingGroup({
    group: 'employeeDashboard',
    parents: ['employeeDashboardAccess'],
    disables: ['admin', 'all'],
    levels: ['overall'],
  }),
  employeeDashboardScoresSegment: new AccessSettingGroup({
    group: 'employeeDashboard',
    parents: ['employeeDashboardAccess'],
    disables: ['admin', 'all'],
    levels: ['overall'],
  }),
  employeeDashboardScoresHierarchy: new AccessSettingGroup({
    group: 'employeeDashboard',
    parents: ['employeeDashboardAccess', 'employeeDashboardScoresSegment'],
    disables: ['admin', 'all'],
    levels: ['overall'],
  }),
  brandingAdmin: new AccessSettingGroup({
    group: 'engage',
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
  }),
  curatedInsights: new AccessSettingGroup({
    group: 'statistics',
    parents: ['dataset', 'priorityRead'],
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
    featureFlag: 'curatedInsights',
    requiresCategoryGroup: [...CORE_QUESTION_SETS],
  }),
  sharePublic: new AccessSettingGroup({
    group: 'statistics',
    parents: ['share'],
    disables: ['admin'],
    levels: ['all', 'manager', 'context'],
    addOn: 'collaboration_shareable',
  }),
};
