import { Record, fromJS } from 'immutable';
import { z } from 'zod';

import { translatedStringSchema } from '@peakon/shared/features/i18next/t';
import { AccessEnum } from '@peakon/shared/schemas/api/attributes';
import { SegmentResponse } from '@peakon/shared/schemas/api/segments';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

const schema = z.object({
  id: z.string(),
  attributeId: z.string().optional(),
  comparisonAccess: AccessEnum.optional(),
  name: translatedStringSchema.optional(),
  nameTranslated: translatedStringSchema.optional(),
  negativeCount: z.number().nonnegative().optional(),
  positiveCount: z.number().nonnegative().optional(),
  segmentCount: z.number().nonnegative(),
});
type Schema = z.infer<typeof schema>;

// eslint-disable-next-line import/no-default-export
export default class SegmentGroup
  extends Record({
    attributeId: undefined,
    comparisonAccess: undefined,
    id: undefined,
    name: undefined,
    nameTranslated: undefined,
    negativeCount: undefined,
    positiveCount: undefined,
    segmentCount: undefined,
  })
  implements Schema
{
  id!: Schema['id'];
  attributeId: Schema['attributeId'];
  comparisonAccess: Schema['comparisonAccess'];
  name: Schema['name'];
  nameTranslated: Schema['nameTranslated'];
  negativeCount: Schema['negativeCount'];
  positiveCount: Schema['positiveCount'];
  segmentCount!: Schema['segmentCount'];

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'SegmentGroup',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  get type() {
    if (this.id !== 'critical' && this.id !== 'link') {
      return 'attribute';
    }

    return this.id;
  }

  isCritical() {
    return this.id === 'critical';
  }

  isLink() {
    return this.id === 'link';
  }

  static createFromApi(data: SegmentResponse) {
    const {
      id,
      attributes,
      relationships: { attribute },
    } = data;

    let attributeProps;
    if (attribute) {
      const {
        id: attributeId,
        attributes: { comparisonAccess, name, nameTranslated } = {},
      } = attribute;

      attributeProps = {
        attributeId,
        comparisonAccess,
        name,
        nameTranslated,
      };
    }

    return new SegmentGroup(
      fromJS({
        id,
        ...attributes,
        ...attributeProps,
      }),
    );
  }

  isRestricted() {
    return this.id !== 'link' && this.comparisonAccess === 'restricted';
  }
}
