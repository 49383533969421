import React, { useEffect, useState } from 'react';

import classnames from 'classnames';
import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';
import trim from 'lodash/trim';
import uniq from 'lodash/uniq';
import uniqueId from 'lodash/uniqueId';
import { Prompt } from 'react-router';
import { z } from 'zod';

import { EditAddIcon as AddIcon } from '@peakon/bedrock/icons/system';
import {
  Button,
  IconButton,
  UnstyledButton,
} from '@peakon/bedrock/react/button';
import { Card } from '@peakon/bedrock/react/card';
import { Heading3 } from '@peakon/bedrock/react/typography';
import { InputField, Label, Select } from '@peakon/components';
import { getDefaultLocaleInfo } from '@peakon/shared/features/i18next/helpers';
import { t } from '@peakon/shared/features/i18next/t';
import { validateData } from '@peakon/shared/utils/validateData/validateData';

import { getAnalyzerLocales } from './../../../../constants/locales';
import { useCreateConceptMutation } from './queries/useCreateConceptMutation';
import { useCreateKeywordMutation } from './queries/useCreateKeywordMutation';
import { useDeleteKeywordMutation } from './queries/useDeleteKeywordMutation';
import { useUpdateConceptMutation } from './queries/useUpdateConceptMutation';
import { sensitiveKeywordSchema } from './schemas';
import {
  SensitiveConcept,
  SensitiveConceptKeyword,
} from './SensitiveCommentsConcept';
import {
  showSuccessNotification,
  catchHandler,
} from '../../../../actions/NotificationActions';
import { useAppDispatch } from '../../../../utils/reduxHooks';

import styles from './formStyles.css';

const responseSchema = z.object({
  data: sensitiveKeywordSchema,
});

export type SensitiveCommentsConceptFormProps = {
  concept: SensitiveConcept;
  canModifyKeywords: boolean;
  isCreating: boolean;
  onCancelled: () => void;
  onDelete?: () => void;
  onDeleteKeyword?: (keywordId: string) => void;
  onAddKeyword?: (keywords: SensitiveConceptKeyword) => void;
  'aria-labelledby'?: React.ComponentProps<'section'>['aria-labelledby'];
  onSave: (conceptName: string) => void;
};

type SensitiveKeyword = {
  id: string;
  keyword: string;
  locale: string;
  primaryLocale: boolean;
};

type AnalyzerLocales = ReturnType<typeof getAnalyzerLocales>;

type Locale = {
  locale: {
    type: 'item';
    id: AnalyzerLocales[number]['id'];
    title: AnalyzerLocales[number]['title'];
  };
  sensitiveKeywords: SensitiveKeyword[];
};

type SupportedLang = {
  value: AnalyzerLocales[number]['id'];
  label: AnalyzerLocales[number]['title'];
};

export const SensitiveCommentsConceptForm = ({
  concept,
  canModifyKeywords,
  isCreating,
  onCancelled,
  onDelete,
  onSave,
  onAddKeyword,
  onDeleteKeyword,
  'aria-labelledby': ariaLabelledBy,
}: SensitiveCommentsConceptFormProps) => {
  const [isDirty, setIsDirty] = useState(false);
  const dispatch = useAppDispatch();
  const [currentConcept, setCurrentConcept] = useState(concept);

  const [locales, setLocales] = useState<Locale[]>([]);
  const [supportedLanguages, setSupportedLanguages] =
    useState<SupportedLang[]>();
  const [selectedLanguage, setSelectedLanguage] = useState<SupportedLang>();
  const [monitoredWord, setMonitoredWord] = useState<string>('');

  useEffect(() => {
    setIsDirty(
      !isEqual(currentConcept, concept) || Boolean(monitoredWord.length),
    );
  }, [currentConcept, concept, monitoredWord]);

  const { mutate: createConcept } = useCreateConceptMutation({
    onSuccess: onSave,
  });

  const { mutate: updateConcept } = useUpdateConceptMutation({
    onSuccess: onSave,
  });

  const { mutateAsync: createSensitiveKeyword } = useCreateKeywordMutation();

  const { mutate: deleteKeywordMutation } = useDeleteKeywordMutation();

  useEffect(() => {
    mapLocales(currentConcept);
  }, [currentConcept]);

  useEffect(() => {
    const analyzerLocales = getAnalyzerLocales({
      shouldTranslateTitle: true,
    });

    const supportedLangs: SupportedLang[] = [];
    analyzerLocales.forEach((language) => {
      supportedLangs.push({ value: language.id, label: language.title });
    });

    setSupportedLanguages(supportedLangs);
  }, []);

  const mapLocales = (conceptToMap: SensitiveConcept) => {
    const analyzerLocales = getAnalyzerLocales({
      shouldTranslateTitle: true,
    });

    setLocales([]);
    const byLocaleId = groupBy(conceptToMap.keywords, 'locale');
    for (const [localeId, sensitiveKeywords] of Object.entries(byLocaleId)) {
      const analyzerLocale = analyzerLocales.find(
        (locale) => locale.id === localeId,
      );
      if (analyzerLocale === undefined) {
        continue;
      }

      setLocales((currentValues) => [
        ...currentValues,
        {
          locale: {
            type: 'item',
            id: analyzerLocale.id,
            title: analyzerLocale.title,
          },
          sensitiveKeywords,
        },
      ]);
    }
  };

  const getLocale = (): string => {
    return selectedLanguage?.value || getDefaultLocaleInfo().language;
  };

  const handleWordAdded = async (
    calledFromSave: boolean,
    // @ts-expect-error TS(2366): Function lacks ending return statement and return ... Remove this comment to see the full error message
  ): Promise<SensitiveConceptKeyword[]> => {
    if (!monitoredWord) {
      return [];
    }

    const locale = getLocale();

    const keywords = uniq(
      monitoredWord
        .split(',')
        .map((kw) => trim(kw))
        .filter((kw) => kw.length > 0),
    );

    const hasConcept = currentConcept.id;

    if (!hasConcept) {
      const addedKeywords: SensitiveConceptKeyword[] = [];

      keywords.forEach((keyword) => {
        const existingKeyword = currentConcept.keywords.find(
          (k) => k.keyword.toLowerCase() === keyword.toLowerCase(),
        );

        // avoid duplicates
        if (existingKeyword) {
          return;
        }

        const newKeyword = {
          id: uniqueId('sensitive_'),
          locale,
          keyword,
          primaryLocale: currentConcept.preferredLocale
            ? locale === currentConcept.preferredLocale
            : true,
        };

        addedKeywords.push(newKeyword);
      });
      // word added by clicking + button, we can update state
      if (!calledFromSave) {
        const array = currentConcept.keywords.slice().concat(addedKeywords);
        const newObj = { ...currentConcept, keywords: array };
        setCurrentConcept(newObj);
      }

      setMonitoredWord('');
      return addedKeywords;
    }

    const promises = keywords.map((keyword) => {
      const sensitiveKeyword = {
        keyword,
        locale,
      };

      return createSensitiveKeyword({
        sensitiveKeyword,
        sensitiveConceptId: currentConcept.id,
      });
    });

    const successResponses: PromiseFulfilledResult<unknown>[] = [];
    const failureResponses: PromiseRejectedResult[] = [];

    const responses = await Promise.allSettled(promises);
    responses.forEach((response) => {
      if (response.status === 'fulfilled') {
        successResponses.push(response);
        const sensitiveKeyword = validateData(response.value, responseSchema, {
          errorMessagePrefix: 'createKeyword-responseSchema',
        });
        const {
          id,
          attributes: { locale: keywordLocale, keyword },
        } = sensitiveKeyword.data;

        const addedKeyword = {
          id,
          locale: keywordLocale,
          keyword,
          primaryLocale: currentConcept.preferredLocale
            ? keywordLocale === currentConcept.preferredLocale
            : true,
        };

        // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        onAddKeyword(addedKeyword);
        currentConcept.keywords.push(addedKeyword);
      } else {
        failureResponses.push(response);
      }
    });

    mapLocales(currentConcept);
    setMonitoredWord('');

    if (successResponses.length) {
      dispatch(
        showSuccessNotification({
          title: t('notifications__success'),
          message: t('engagement__settings__sensitive_comments_custom_created'),
          // TODO (PEAKONADX-981): Uncomment when translations are available
          // message:
          //   t('engagement__settings__sensitive_comments_keyword_created', {
          //     replace: { count: successResponses.length },
          //   }),
        }),
      );
    }
    if (failureResponses.length) {
      failureResponses.forEach((response) => {
        dispatch(catchHandler(response.reason));
      });
    }
  };

  const removeSensitiveKeywordLocally = (
    sensitiveKeyword: SensitiveConceptKeyword,
  ) => {
    const { id } = sensitiveKeyword;

    const index = currentConcept.keywords.findIndex((sk) => sk.id === id);
    if (index > -1) {
      setCurrentConcept({
        ...currentConcept,
        keywords: currentConcept.keywords.filter((sk) => sk.id !== id),
      });
      onDeleteKeyword && onDeleteKeyword(id);
    }
  };

  const handleRemoveKeyword = (keyword: SensitiveConceptKeyword) =>
    currentConcept.id
      ? deleteKeywordMutation(keyword, {
          onSuccess: () => removeSensitiveKeywordLocally(keyword),
        })
      : removeSensitiveKeywordLocally(keyword);

  const handleConceptNameChanged = (name: string) => {
    setCurrentConcept({ ...currentConcept, name });
  };

  const handleCreateConcept = async (
    newKeywords: SensitiveConceptKeyword[],
  ) => {
    let sensitiveKeywords = [];

    sensitiveKeywords = currentConcept.keywords
      .concat(newKeywords)
      ?.filter(({ id }) => id.includes('sensitive_'))
      .map((sensitiveKeyword) => {
        const { keyword, locale } = sensitiveKeyword;

        return {
          keyword,
          locale,
        };
      });

    if (sensitiveKeywords.length === 0) {
      sensitiveKeywords.push({
        keyword: currentConcept.name,
        locale: getLocale(),
      });
    }

    createConcept({ name: currentConcept.name, sensitiveKeywords });
  };

  const handleSaveConcept = async () => {
    if (currentConcept?.id && currentConcept?.name) {
      return updateConcept({
        id: currentConcept.id,
        name: currentConcept.name,
      });
    }

    const newKeywords = await handleWordAdded(true);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Automatically disabled here to enable the rule globally
    handleCreateConcept(newKeywords);
  };

  return (
    <section
      aria-labelledby={ariaLabelledBy}
      className={isCreating ? styles.rootCreateMode : styles.root}
    >
      <Prompt
        when={isDirty}
        message={() => t('schedules__form__unsaved_changes')}
      />
      <Card>
        {isCreating && (
          <React.Fragment>
            <div className={styles.createHeader}>
              <span>
                {t('engagement__settings__sensitive_comments_custom_create')}
              </span>
            </div>

            <div className={styles.separator} />
          </React.Fragment>
        )}
        <div className={styles.fields}>
          <div className={styles.field}>
            <div className={styles.fieldLabel}>
              {t('engagement__settings__sensitive_comments_input_concept')}
            </div>

            {currentConcept.standard ? (
              <h4 className={styles.fieldTitle}>{currentConcept.name}</h4>
            ) : (
              <InputField
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                value={currentConcept.name}
                onChange={handleConceptNameChanged}
                id="input-concept"
                required
                placeholder={t(
                  'engagement__settings__sensitive_comments_placeholder_concept',
                )}
              />
            )}
          </div>
        </div>
        <div className={styles.separator} />

        <div className={styles.variationsList}>
          <Heading3 level={concept.standard ? 5 : 4}>
            {t('engagement__settings__sensitive_comments_list-variations')}
          </Heading3>
          {locales.length === 0 && (
            <div className={styles.variationsListEmpty}>
              <span>
                {t('engagement__settings__sensitive_comments_list_empty')}
              </span>
            </div>
          )}

          {locales.length > 0 && (
            <table>
              <thead>
                <tr>
                  <td>
                    {t(
                      'engagement__settings__sensitive_comments_list_language',
                    )}
                  </td>
                  <td>
                    {t(
                      'engagement__settings__sensitive_comments_list_monitored-words',
                    )}
                  </td>
                </tr>
              </thead>
              <tbody>
                {locales.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {item.locale.id ? (
                        <span>{item.locale.title}</span>
                      ) : (
                        <span>
                          {t(
                            'engagement__settings__sensitive_comments_no_localization',
                          )}
                        </span>
                      )}
                    </td>

                    <td>
                      {item.sensitiveKeywords.map((keyword) => (
                        <div key={keyword.id} className={styles.variationsWord}>
                          <span>{keyword.keyword}</span>
                          {canModifyKeywords && (
                            <UnstyledButton
                              className={styles.variationsWordDeleteButton}
                              onClick={() => handleRemoveKeyword(keyword)}
                              accessibleName={t(
                                'engagement__settings__sensitive_comments_list_delete_keywords',
                                {
                                  replace: { keyword: keyword.keyword },
                                },
                              )}
                            >
                              &#x2715;
                            </UnstyledButton>
                          )}
                        </div>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        {canModifyKeywords && (
          <React.Fragment>
            <div className={styles.variationsCreate}>
              <Heading3 level={concept.standard ? 5 : 4}>
                {t(
                  'engagement__settings__sensitive_comments_create-variations',
                )}
              </Heading3>

              <div className={styles.variationsCreateForm}>
                <div className={styles.variationsCreateLanguage}>
                  <Label htmlFor="target-language">
                    {t(
                      'engagement__settings__sensitive_comments_label_target_language',
                    )}
                  </Label>

                  <Select
                    isClearable
                    value={selectedLanguage}
                    options={supportedLanguages}
                    onChange={(selectedItem: SupportedLang) =>
                      setSelectedLanguage(selectedItem)
                    }
                    placeholder={t('survey_email_preview__language_automatic')}
                    stretched
                    inputId="target-language"
                  />
                </div>

                <div className={styles.variationsCreateWords}>
                  <Label htmlFor="input-keyword">
                    {t(
                      'engagement__settings__sensitive_comments_label_keywords',
                    )}
                  </Label>
                  <div className={styles.inputs}>
                    <InputField
                      id="input-keyword"
                      placeholder={t(
                        'engagement__settings__sensitive_comments_placeholder_keywords',
                      )}
                      value={monitoredWord}
                      onChange={(val) => setMonitoredWord(val)}
                    />

                    <IconButton
                      disabled={monitoredWord?.length === 0}
                      variant="primary"
                      size="medium"
                      onClick={() => handleWordAdded(false)}
                      icon={<AddIcon />}
                      accessibleName={t(
                        'engagement__settings__sensitive_comments_create-variations',
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.separator} />
            {canModifyKeywords && concept && !concept.type && (
              <div
                className={classnames(
                  styles.footer,
                  isCreating
                    ? styles.footerEndAlign
                    : styles.footerSpaceBetween,
                )}
              >
                {!isCreating && (
                  <div className={styles.deleteButton}>
                    <Button
                      variant="warning"
                      onClick={onDelete}
                      data-test-id="delete-concept"
                    >
                      {t('delete')}
                    </Button>
                  </div>
                )}
                <div className={styles.createButtons}>
                  <Button variant="secondary" onClick={onCancelled}>
                    {t('cancel')}
                  </Button>
                  <Button
                    variant="primary"
                    onClick={handleSaveConcept}
                    disabled={
                      currentConcept.name?.length === 0 ||
                      !isDirty ||
                      Boolean(monitoredWord.length)
                    }
                  >
                    {t('save')}
                  </Button>
                </div>
              </div>
            )}
          </React.Fragment>
        )}
      </Card>
    </section>
  );
};
