import { useMutation } from '@tanstack/react-query';

import { t } from '@peakon/shared/features/i18next/t';
import api from '@peakon/shared/utils/api';

import {
  catchHandler,
  showSuccessNotification,
} from '../../../../../actions/NotificationActions';
import { useAppDispatch } from '../../../../../utils/reduxHooks';

type QueryParams = {
  onSuccess: () => void;
};

const deleteConcept = async (conceptId: string): Promise<void> =>
  // @ts-expect-error Type 'unknown' is not assignable to type 'void'.ts(2322)
  api.delete(`/sensitive/concepts/${conceptId}`, null);

export const useDeleteConceptMutation = ({ onSuccess }: QueryParams) => {
  const dispatch = useAppDispatch();
  return useMutation({
    mutationFn: deleteConcept,
    onError: (error) => dispatch(catchHandler(error)),
    onSuccess: () => {
      onSuccess();
      dispatch(
        showSuccessNotification({
          title: t('notifications__success'),
          /* TODO: uncommment this once the copy is translated */
          // message: t('engagement__settings__sensitive_comments_custom_concept_deleted'),
        }),
      );
    },
  });
};
