import { Record, fromJS, Map } from 'immutable';
import get from 'lodash/get';
import { z } from 'zod';

import { translatedStringSchema } from '@peakon/shared/features/i18next/t';
import { TypeEnum } from '@peakon/shared/schemas/api/attributes';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import Attribute from './AttributeRecord';
import { ALL_QUESTION_SETS } from './constants/questionSets';
import Segment from './SegmentRecord';
import { fromJsonApi, validateTestingSchema } from './utils';

const schema = z.object({
  id: z.string(),
});
const testingSchema = schema.extend({
  abbreviation: translatedStringSchema,
  attribute: z.object({}).passthrough(),
  classification: z.enum(['priority', 'strength']).optional(),
  contextId: z.string().optional(),
  critical: z.object({}).passthrough().optional(),
  direct: z.boolean(),
  employeeCount: z.number().optional(),
  group: z.enum([...ALL_QUESTION_SETS, 'values']).optional(),
  links: z.number().nonnegative().optional(),
  loading: z.boolean().optional(),
  logo: z.string().nullable(),
  name: translatedStringSchema,
  nameTranslated: translatedStringSchema,
  priority: z.object({}).passthrough().optional(),
  segment: z.object({}).passthrough().optional(),
  source: z.enum(['peakon', 'company']),
  type: TypeEnum.optional(),
});
type Schema = z.infer<typeof schema>;

// eslint-disable-next-line import/no-default-export
export default class EngagementSegment
  extends Record({
    id: undefined,
    abbreviation: undefined,
    attribute: new Attribute(),
    classification: undefined,
    critical: Map(),
    direct: undefined,
    email: undefined,
    employeeCount: undefined,
    identifier: undefined,
    links: undefined,
    logo: undefined,
    name: undefined,
    nameTranslated: undefined,
    priority: Map(),
    source: undefined,
    loading: false,
    group: undefined,
    segment: undefined,
  })
  implements Schema
{
  critical: $TSFixMe;
  links: $TSFixMe;
  attribute!: Attribute;
  id!: Schema['id'];
  classification?: 'priority' | 'strength';

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'EngagementSegment',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'EngagementSegment',
      environments: ['local', 'staging', 'production'],
      logLevel: 'warning',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  hasLinks() {
    return typeof this.links !== 'undefined' && this.links > 0;
  }

  isCritical() {
    return this.critical && !this.critical.isEmpty();
  }

  static revive({ attribute, ...data }: $TSFixMe) {
    return new EngagementSegment(
      fromJS({
        ...data,
        loading: false,
        attribute: attribute ? Attribute.revive(attribute) : undefined,
      }),
    );
  }

  static createFromApi(
    data: $TSFixMe,
    { attributeKey = 'relationships.segment.relationships.attribute' } = {},
  ) {
    const attribute = get(data, attributeKey);
    const segment = get(data, 'relationships.segment');
    const parsed = fromJsonApi(data);
    const links = get(data, 'attributes.links');

    return new EngagementSegment(
      fromJS({
        ...parsed,
        links,
        attribute: attribute ? Attribute.createFromApi(attribute) : undefined,
        segment: segment ? Segment.createFromApi(segment) : undefined,
      }),
    );
  }
}
