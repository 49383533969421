import { List, Map } from 'immutable';
import get from 'lodash/get';

import { ManagerActions } from '@peakon/records/metrics';
import Pagination from '@peakon/records/PaginationRecord';

import { getPagesFromLinks, getTotalFromMeta } from '../../utils/getParams';

type State = Map<string, Pagination<ManagerActions>>;

const segmentActionManagers = (
  state = Map() satisfies State,
  action: $TSFixMe,
): State => {
  switch (action.type) {
    case 'MANAGER_METRICS_ACTION_MANAGERS_LOADING': {
      const { segmentId } = action.data;

      if (!state.has(segmentId)) {
        return state.set(
          segmentId,
          new Pagination({ id: segmentId, isLoading: true }),
        );
      }

      return state.update(segmentId, (pagination) =>
        pagination.set('isLoading', true),
      );
    }

    case 'MANAGER_METRICS_ACTION_MANAGERS_SUCCESS': {
      const { segmentId, data: managers, links, meta } = action.data;

      if (!state.has(segmentId)) {
        return state;
      }

      return state.update(segmentId, (pagination) =>
        pagination.merge({
          isLoading: false,
          items: List(managers.map(ManagerActions.createFromApi)),
          nextUrl: get(links, 'next'),
          // @ts-expect-error TS(2339): Property 'self' does not exist on type '{}'.
          self: getPagesFromLinks(links).self,
          total: getTotalFromMeta(meta),
        }),
      );
    }

    case 'MANAGER_METRICS_ACTION_MANAGERS_FAILED': {
      const { segmentId } = action.data;

      return state.update(segmentId, (pagination) =>
        pagination.set('isLoading', false),
      );
    }

    case 'MANAGER_METRICS_ACTION_MANAGERS_APPEND_LOADING': {
      const { segmentId } = action.data;

      return state.update(segmentId, (pagination) =>
        pagination.set('isLoading', true),
      );
    }

    case 'MANAGER_METRICS_ACTION_MANAGERS_APPEND_SUCCESS': {
      const {
        segmentId,
        data: managers,
        links: { self, next },
      } = action.data;

      if (!state.has(segmentId)) {
        return state;
      }

      return state.update(segmentId, (pagination) =>
        pagination.merge({
          isLoading: false,
          items: pagination.items.concat(
            List(managers.map(ManagerActions.createFromApi)),
          ),
          nextUrl: next,
          self,
        }),
      );
    }

    case 'MANAGER_METRICS_ACTION_MANAGERS_APPEND_FAILED': {
      const { segmentId } = action.data;

      if (!state.has(segmentId)) {
        return state;
      }

      return state.update(segmentId, (pagination) =>
        pagination.set('isLoading', false),
      );
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default segmentActionManagers;
